import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { supabase } from '../../lib/supabase'

export default function AuthCallback() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const handleCallback = async () => {
      try {
        // Get code from URL
        const code = searchParams.get('code')
        console.log('Code from URL:', code)

        if (!code) {
          // Check for existing session
          const { data: { session } } = await supabase.auth.getSession()
          if (session?.user) {
            console.log('Using existing session for:', session.user.email)
            await ensureProfile(session.user)
            navigate('/generate', { replace: true })
            return
          }
          throw new Error('No authentication code found')
        }

        // Exchange code for session
        console.log('Exchanging code for session...')
        const { data, error: signInError } = await supabase.auth.exchangeCodeForSession(code)

        if (signInError) {
          console.error('Sign in error:', signInError)
          throw signInError
        }

        if (!data?.session?.user) {
          console.error('No user in session data:', data)
          throw new Error('Failed to get user session')
        }

        // Check if user already exists
        const { data: existingProfile } = await supabase
          .from('profiles')
          .select('id')
          .eq('id', data.session.user.id)
          .single()

        if (!existingProfile) {
          // Only create profile for new users
          await ensureProfile(data.session.user)
        }

        console.log('Auth successful, redirecting...')
        navigate('/generate', { replace: true })
      } catch (err) {
        console.error('Auth callback error:', err)
        setTimeout(() => {
          navigate('/login', { replace: true })
        }, 2000)
      }
    }

    handleCallback()
  }, [navigate, searchParams])

  async function ensureProfile(user: any) {
    try {
      console.log('Creating profile for new user:', user.email)
      const { error: profileError } = await supabase
        .from('profiles')
        .insert([{
          id: user.id,
          email: user.email,
          updated_at: new Date().toISOString(),
          remaining_generations: 5,
          is_premium: false,
        }])

      if (profileError) {
        console.error('Profile creation error:', profileError)
      } else {
        console.log('Profile created successfully')
      }
    } catch (error) {
      console.error('Profile creation error:', error)
    }
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-gray-50 to-gray-100">
      <div className="text-center">
        <div className="mx-auto h-12 w-12 animate-spin">
          <svg className="text-blue-500" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </div>
        <h2 className="mt-4 text-lg font-medium text-gray-900">Verifying your login...</h2>
        <p className="mt-2 text-sm text-gray-500">Please wait while we complete the process.</p>
      </div>
    </div>
  )
} 