import { PlanType } from '../types/database';

export type StripePriceId = string;

export const STRIPE_PRICE_IDS = {
  HOLIDAY: process.env.VITE_STRIPE_PRICE_HOLIDAY || 'price_1QYrTeQMKtFTp2jF8i4ci8V4',
} as const;

export interface PriceConfig {
  amount: number;
  generations: number;
  name: string;
  isPremiumGallery: boolean;
}

export const PRICES: Record<keyof typeof STRIPE_PRICE_IDS, PriceConfig> = {
  HOLIDAY: {
    name: "Holiday Bundle",
    amount: 5,
    generations: 20,
    isPremiumGallery: true,
  },
} as const;

export const PLANS: Record<PlanType, {
  name: string;
  price: number;
  generations?: number;
  features: string[];
  priceId: StripePriceId;
}> = {
  free: {
    name: 'Free',
    price: 0,
    generations: 5,
    features: ['5 free generations', 'Basic wallpapers', 'Standard support'],
    priceId: '' as StripePriceId,
  },
  holiday: {
    name: PRICES.HOLIDAY.name,
    price: PRICES.HOLIDAY.amount,
    generations: PRICES.HOLIDAY.generations,
    features: [
      '20 generations',
      'Premium gallery access',
      'HD wallpapers',
      'Priority support',
      'Holiday themed templates',
      'Custom resolutions'
    ],
    priceId: STRIPE_PRICE_IDS.HOLIDAY,
  }
}; 