import { Header } from '../components/Header';
import { useAuth } from '../contexts/AuthContext';
import { Gift, Sparkle } from 'lucide-react';
import { PricingCard } from '../components/pricing/PricingCard';
import { STRIPE_PRICE_IDS } from '../lib/constants';

const pricingTiers = [
  {
    name: "Free",
    price: 0,
    description: "Get started with AI-powered Christmas cards",
    stripePriceId: "",
    features: [
      { text: "5 free generations", included: true },
      { text: "Access to free gallery", included: true },
      { text: "Basic support", included: true },
      { text: "Premium gallery access", included: false },
      { text: "Custom resolutions", included: false },
    ],
  },
  {
    name: "Holiday Bundle",
    price: 5,
    description: "Perfect for creating unique Christmas cards",
    stripePriceId: STRIPE_PRICE_IDS.HOLIDAY,
    features: [
      { text: "20 generations", included: true },
      { text: "Premium gallery access", included: true },
      { text: "HD wallpapers", included: true },
      { text: "Priority support", included: true },
      { text: "Custom resolutions", included: true },
    ],
  },
];

export function Pricing() {
  const { user, loading } = useAuth();

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <Header />
      <div className="pt-16">
        <main className="py-16 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto">
            {/* Holiday Special Banner */}
            <div className="mb-12 p-6 rounded-2xl bg-gradient-to-r from-red-500 to-red-600 text-white shadow-lg">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                  <Gift className="w-8 h-8" />
                  <div>
                    <h3 className="text-lg font-semibold">Holiday Special Offer</h3>
                    <p className="text-red-100">Get premium access and 20 generations for just $5!</p>
                  </div>
                </div>
                <Sparkle className="w-6 h-6 animate-pulse" />
              </div>
            </div>

            {/* Pricing Header */}
            <div className="text-center mb-12">
              <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                Simple, transparent pricing
              </h1>
              <p className="mt-5 text-xl text-gray-500">
                Choose the perfect plan for your Christmas card creation needs
              </p>
              {!user && !loading && (
                <p className="mt-3 text-sm text-gray-500">
                  Sign up to get started with any plan
                </p>
              )}
            </div>

            {/* Pricing Cards */}
            <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto">
              {pricingTiers.map((plan, index) => (
                <PricingCard
                  key={plan.name}
                  plan={plan}
                  isPopular={index === 1} // Holiday bundle is popular
                />
              ))}
            </div>

            {/* FAQ Section */}
            <div className="mt-16 bg-white rounded-2xl shadow-sm p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">
                Frequently Asked Questions
              </h2>
              <dl className="space-y-6">
                <div>
                  <dt className="text-lg font-semibold text-gray-900">
                    What's included in the Holiday Bundle?
                  </dt>
                  <dd className="mt-2 text-gray-500">
                    The Holiday Bundle includes 20 generations, premium gallery access, HD wallpapers, priority support, and custom resolutions. It's a one-time purchase that gives you everything you need to create beautiful Christmas cards.
                  </dd>
                </div>
                <div>
                  <dt className="text-lg font-semibold text-gray-900">
                    How do the generations work?
                  </dt>
                  <dd className="mt-2 text-gray-500">
                    Each generation creates a unique AI-powered Christmas card or wallpaper. You can use your generations anytime during the holiday season.
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}