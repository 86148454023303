import { GeneratedImage } from '../../types/database';
import { X, Download, Share2, Loader2, Image as ImageIcon } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { Button } from '../ui/Button';
import { supabase } from '../../lib/supabase';

interface ImageModalProps {
  image: GeneratedImage;
  onClose: () => void;
}

export function ImageModal({ image, onClose }: ImageModalProps) {
  const [downloading, setDownloading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleDownload = async () => {
    if (downloading) return;
    
    setDownloading(true);
    try {
      const response = await fetch(image.url);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `christmas-ai-${image.id}.png`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      if ('downloads' in image) {
        await supabase.rpc('increment_downloads', { image_id: image.id });
      }

      toast.success('Image downloaded successfully!');
    } catch (error) {
      console.error('Error downloading image:', error);
      toast.error('Failed to download image');
    } finally {
      setDownloading(false);
    }
  };

  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(image.url);
      toast.success('Image URL copied to clipboard!');
    } catch (error) {
      console.error('Error copying to clipboard:', error);
      toast.error('Failed to copy URL');
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50 overflow-y-auto"
        onClick={onClose}
      >
        <div className="flex min-h-screen items-center justify-center px-4 py-6 sm:p-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={onClose} />
          
          <div className="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all w-full max-w-3xl mx-4 sm:mx-auto">
            <div className="absolute right-0 top-0 z-10 p-4">
              <button
                onClick={onClose}
                className="rounded-lg bg-white/10 p-2 text-gray-400 hover:text-gray-500 backdrop-blur-sm"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            <div className="flex flex-col sm:flex-row items-center justify-between p-4 sm:p-6 border-b border-gray-200">
              <div className="flex items-center space-x-2 mb-4 sm:mb-0">
                <div className="p-2 bg-blue-50 rounded-lg">
                  <ImageIcon className="w-5 h-5 text-blue-500" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-900">Image Details</h3>
                  <p className="text-sm text-gray-500">View and download options</p>
                </div>
              </div>
              <div className="flex flex-wrap gap-2 justify-center sm:justify-end w-full sm:w-auto">
                <Button
                  variant="outline"
                  size="sm"
                  className="flex items-center gap-2"
                  onClick={handleDownload}
                  disabled={!imageLoaded}
                >
                  <Download className="w-4 h-4" />
                  Download
                </Button>
                <Button
                  variant="outline"
                  size="sm"
                  className="flex items-center gap-2"
                  onClick={handleShare}
                >
                  <Share2 className="w-4 h-4" />
                  Share
                </Button>
              </div>
            </div>

            <div className="relative flex justify-center items-center min-h-[200px] max-h-[calc(90vh-200px)] bg-gray-50 p-4">
              {!imageLoaded && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <Loader2 className="w-8 h-8 animate-spin text-gray-400" />
                </div>
              )}
              <img
                src={image.url}
                alt={image.prompt}
                onLoad={() => setImageLoaded(true)}
                className={`max-w-full h-auto max-h-[calc(90vh-200px)] object-contain ${
                  imageLoaded ? 'opacity-100' : 'opacity-0'
                } transition-opacity duration-200`}
              />
            </div>

            <div className="p-4 sm:p-6 bg-white">
              <div className="space-y-4">
                <div>
                  <h3 className="text-lg font-medium text-gray-900">Prompt</h3>
                  <p className="mt-1 text-gray-600 break-words">{image.prompt}</p>
                </div>
                
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 pt-4 border-t border-gray-100">
                  <div className="text-sm text-gray-500">
                    Created on{' '}
                    {new Date(image.created_at).toLocaleDateString(undefined, {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                  </div>
                  
                  <div className="text-sm text-gray-500">
                    Model: {image.model || 'Standard'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}