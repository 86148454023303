import React, { useEffect, useState, useRef } from 'react';
import { Header } from '../components/Header';
import { GeneratedImage } from '../types/database';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';
import { ImageModal } from '../components/gallery/ImageModal';
import { Filter, Search, Smartphone, Monitor, Image as ImageIcon, Sparkles, Loader2 } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-hot-toast';

type FilterType = 'all' | 'landscape' | 'portrait' | 'cozy' | 'modern';
type GalleryType = 'personal' | 'premium' | 'free';

export default function Gallery() {
  const [images, setImages] = useState<GeneratedImage[]>([]);
  const [premiumImages, setPremiumImages] = useState<GeneratedImage[]>([]);
  const [freeImages, setFreeImages] = useState<GeneratedImage[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState<GeneratedImage | null>(null);
  const [activeFilter, setActiveFilter] = useState<FilterType>('all');
  const [activeGallery, setActiveGallery] = useState<GalleryType>('personal');
  const [searchQuery, setSearchQuery] = useState('');
  const { user, profile } = useAuth();
  const mounted = useRef(true);
  const loadingTimeout = useRef<NodeJS.Timeout>();

  const filters: { id: FilterType; label: string; icon: React.ReactNode }[] = [
    { id: 'all', label: 'All Images', icon: <ImageIcon className="w-4 h-4" /> },
    { id: 'landscape', label: 'Landscape', icon: <Monitor className="w-4 h-4" /> },
    { id: 'portrait', label: 'Portrait', icon: <Smartphone className="w-4 h-4" /> },
    { id: 'cozy', label: 'Cozy', icon: null },
    { id: 'modern', label: 'Modern', icon: null }
  ];

  const fetchFreeImages = async () => {
    try {
      console.log('Fetching free gallery images...');
      const { data, error } = await supabase
        .from('free_gallery')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Free gallery fetch error:', error);
        throw error;
      }

      if (data) {
        console.log('Free gallery images fetched:', data.length);
        // Group images by image_set_id
        const groupedImages = data.reduce<Record<string, GeneratedImage[]>>((acc, img) => {
          if (!img.url || !img.url.trim()) return acc;
          
          const setId = img.image_set_id || img.id;
          if (!acc[setId]) {
            acc[setId] = [];
          }
          acc[setId].push(img);
          return acc;
        }, {});

        // For each set, select the appropriate device version
        const processedImages = Object.values(groupedImages).map((imageSet: GeneratedImage[]) => {
          return imageSet.find(img => img.device === 'macbook') || imageSet[0];
        }).filter(Boolean);

        if (mounted.current) {
          setFreeImages(processedImages);
        }
      }
    } catch (err: any) {
      console.error('Error fetching free gallery images:', err);
      if (mounted.current) {
        toast.error(err.message || 'Failed to load free gallery');
      }
    }
  };

  useEffect(() => {
    // Set a maximum loading time of 5 seconds
    loadingTimeout.current = setTimeout(() => {
      if (mounted.current) {
        setLoading(false);
      }
    }, 5000);

    const loadGallery = async () => {
      try {
        if (!user) {
          setLoading(false);
          return;
        }

        console.log('Loading gallery for user:', user.id, 'isPremium:', profile?.is_premium);
        await fetchImages();
        await fetchFreeImages();
        await fetchPremiumImages();
      } catch (err: any) {
        console.error('Error loading gallery:', err);
        if (err.message?.includes('JWT')) {
          toast.error('Session expired. Please log in again.');
        } else if (mounted.current) {
          toast.error(err.message || 'Failed to load gallery');
        }
      } finally {
        if (mounted.current) {
          setLoading(false);
        }
      }
    };

    loadGallery();

    return () => {
      mounted.current = false;
      if (loadingTimeout.current) {
        clearTimeout(loadingTimeout.current);
      }
    };
  }, [user?.id]);

  const fetchImages = async () => {
    if (!user?.id) return;

    try {
      const { data, error } = await supabase
        .from('generated_images')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) {
        if (error.message?.includes('JWT')) {
          throw new Error('Session expired. Please log in again.');
        }
        throw error;
      }

      if (mounted.current) {
        setImages(data?.filter(img => img.url && img.url.trim() !== '') || []);
      }
    } catch (err: any) {
      console.error('Error fetching images:', err);
      if (mounted.current) {
        toast.error(err.message || 'Failed to load images');
      }
    }
  };

  const fetchPremiumImages = async () => {
    try {
      console.log('Fetching premium images...');
      const { data, error } = await supabase
        .from('premium_gallery')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Premium images fetch error:', error);
        throw error;
      }

      if (data) {
        console.log('Premium images fetched:', data.length);
        // Group images by image_set_id
        const groupedImages = data.reduce<Record<string, GeneratedImage[]>>((acc, img) => {
          if (!img.url || !img.url.trim()) return acc;
          
          const setId = img.image_set_id || img.id; // Fallback to id if no set_id
          if (!acc[setId]) {
            acc[setId] = [];
          }
          acc[setId].push(img);
          return acc;
        }, {});

        // For each set, select the appropriate device version
        const processedImages = Object.values(groupedImages).map((imageSet: GeneratedImage[]) => {
          // Default to macbook version if available, otherwise take any version
          return imageSet.find(img => img.device === 'macbook') || imageSet[0];
        }).filter(Boolean);

        console.log('Processed premium images:', processedImages.length);
        if (mounted.current) {
          setPremiumImages(processedImages);
        }
      }
    } catch (err: any) {
      console.error('Error fetching premium images:', err);
      if (mounted.current) {
        toast.error(err.message || 'Failed to load premium images');
      }
    }
  };

  const currentImages = activeGallery === 'personal' 
    ? images 
    : activeGallery === 'premium' 
      ? premiumImages 
      : freeImages;

  const filteredImages = currentImages.filter(image => {
    // Determine orientation based on aspect ratio
    const isLandscape = image.width > image.height;
    const isPortrait = image.width < image.height;

    const matchesFilter = activeFilter === 'all' || 
      (activeFilter === 'landscape' && isLandscape) ||
      (activeFilter === 'portrait' && isPortrait) ||
      (activeFilter === 'cozy' && image.prompt?.toLowerCase().includes('cozy')) ||
      (activeFilter === 'modern' && image.prompt?.toLowerCase().includes('modern'));

    const matchesSearch = !searchQuery || 
      image.prompt?.toLowerCase().includes(searchQuery.toLowerCase());

    return matchesFilter && matchesSearch;
  });

  if (!user) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
        <Header />
        <main className="container mx-auto px-4 pt-20 pb-8">
          <div className="text-center py-12">
            <p className="text-gray-600">Please log in to view your gallery.</p>
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <Header />
      <main className="container mx-auto px-4 pt-20 pb-8">
        <div className="flex flex-col space-y-6">
          <div className="bg-white rounded-lg shadow-sm p-4 space-y-4">
            {/* Gallery controls */}
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
              <div className="flex items-center space-x-4 w-full sm:w-auto">
                {profile?.is_premium && (
                  <span className="flex items-center text-amber-500">
                    <Sparkles className="w-4 h-4 mr-1" />
                    Premium
                  </span>
                )}
              </div>
              <div className="flex flex-wrap gap-4 w-full sm:w-auto">
                <button
                  onClick={() => setActiveGallery('personal')}
                  className={`flex-1 sm:flex-none px-4 py-2 rounded-lg flex items-center justify-center ${
                    activeGallery === 'personal'
                      ? 'bg-blue-100 text-blue-600'
                      : 'hover:bg-gray-100'
                  }`}
                >
                  <ImageIcon className="w-4 h-4 mr-2" />
                  Personal Gallery
                </button>
                <button
                  onClick={() => setActiveGallery('free')}
                  className={`flex-1 sm:flex-none px-4 py-2 rounded-lg flex items-center justify-center ${
                    activeGallery === 'free'
                      ? 'bg-green-100 text-green-600'
                      : 'hover:bg-gray-100'
                  }`}
                >
                  <ImageIcon className="w-4 h-4 mr-2" />
                  Free Gallery
                </button>
                <button
                  onClick={() => setActiveGallery('premium')}
                  className={`flex-1 sm:flex-none px-4 py-2 rounded-lg flex items-center justify-center ${
                    activeGallery === 'premium'
                      ? 'bg-amber-100 text-amber-600'
                      : 'hover:bg-gray-100'
                  }`}
                >
                  <Sparkles className="w-4 h-4 mr-2" />
                  Premium Gallery
                </button>
              </div>
            </div>

            {/* Search and filters */}
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
              <div className="relative w-full sm:w-96">
                <input
                  type="text"
                  placeholder="Search by prompt..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full px-4 py-2 pl-10 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <Search className="w-4 h-4 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
              </div>
              <div className="flex items-center space-x-2 overflow-x-auto w-full sm:w-auto pb-2 sm:pb-0">
                <Filter className="w-4 h-4 text-gray-400 flex-shrink-0" />
                <div className="flex gap-2 overflow-x-auto pb-2 sm:pb-0 flex-nowrap">
                  {filters.map((filter) => (
                    <button
                      key={filter.id}
                      onClick={() => setActiveFilter(filter.id)}
                      className={`px-3 py-1.5 rounded-lg flex items-center whitespace-nowrap flex-shrink-0 ${
                        activeFilter === filter.id
                          ? 'bg-blue-100 text-blue-600'
                          : 'hover:bg-gray-100'
                      }`}
                    >
                      {filter.icon && <span className="mr-2">{filter.icon}</span>}
                      {filter.label}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Gallery content */}
          {loading ? (
            <div className="flex justify-center items-center min-h-[200px]">
              <Loader2 className="w-8 h-8 text-blue-500 animate-spin" />
            </div>
          ) : filteredImages.length === 0 ? (
            <div className="text-center py-12">
              <p className="text-gray-600">No images found. Try adjusting your filters or creating new images!</p>
            </div>
          ) : (
            <motion.div 
              layout
              className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6 auto-rows-max"
            >
              <AnimatePresence>
                {filteredImages.map((image) => (
                  <motion.div
                    key={image.id}
                    layout
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, scale: 0.95 }}
                    transition={{ duration: 0.3 }}
                    className="relative group cursor-pointer overflow-hidden rounded-xl bg-white shadow-sm hover:shadow-xl transition-all duration-300"
                    onClick={() => setSelectedImage(image)}
                  >
                    <div className="aspect-[16/9] w-full relative overflow-hidden">
                      <img
                        src={image.url}
                        alt={image.prompt}
                        className="absolute inset-0 w-full h-full object-cover transition-all duration-500 group-hover:scale-110"
                        loading="lazy"
                        onError={(e) => {
                          const target = e.target as HTMLImageElement;
                          target.src = 'https://via.placeholder.com/1920x1080?text=Image+Failed+to+Load';
                        }}
                      />
                    </div>
                    <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-all duration-300">
                      <div className="absolute inset-x-0 bottom-0 p-4 transform translate-y-2 group-hover:translate-y-0 transition-transform duration-300">
                        <p className="text-white text-sm font-medium line-clamp-2 mb-1">{image.prompt}</p>
                        <div className="flex items-center justify-between text-white/80 text-xs">
                          <span>
                            {new Date(image.created_at).toLocaleDateString(undefined, {
                              month: 'short',
                              day: 'numeric'
                            })}
                          </span>
                          <span className="px-2 py-1 bg-white/20 rounded-full">
                            {image.width > image.height ? 'Landscape' : 'Portrait'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </AnimatePresence>
            </motion.div>
          )}

          {selectedImage && (
            <ImageModal
              image={selectedImage}
              onClose={() => setSelectedImage(null)}
            />
          )}
        </div>
      </main>
    </div>
  );
}