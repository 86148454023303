import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { useAuth } from './AuthContext';
import { toast } from 'react-hot-toast';
import { PLANS } from '../lib/constants';

interface GenerationsContextType {
  remainingGenerations: number;
  loading: boolean;
  refreshGenerations: () => Promise<void>;
  decrementGenerations: () => Promise<any>;
  addPremiumGenerations: () => Promise<void>;
}

const GenerationsContext = createContext<GenerationsContextType | undefined>(undefined);

const INITIAL_FREE_GENERATIONS = PLANS.free.generations || 5;
const HOLIDAY_BONUS_GENERATIONS = PLANS.holiday.generations || 20;

export function GenerationsProvider({ children }: { children: React.ReactNode }) {
  const [remainingGenerations, setRemainingGenerations] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  const fetchGenerations = async () => {
    try {
      if (!user) {
        setRemainingGenerations(0);
        setLoading(false);
        return;
      }

      const { data, error } = await supabase
        .from('profiles')
        .select('remaining_generations, is_premium')
        .eq('id', user.id)
        .maybeSingle();

      if (error) {
        console.error('Error fetching generations:', error);
        throw error;
      }

      if (data) {
        setRemainingGenerations(Math.max(0, data.remaining_generations || 0));
      } else {
        // If no profile exists, create one with initial generations
        const { data: newProfile, error: createError } = await supabase
          .from('profiles')
          .upsert({
            id: user.id,
            remaining_generations: INITIAL_FREE_GENERATIONS,
            is_premium: false,
            updated_at: new Date().toISOString()
          })
          .select()
          .single();

        if (createError) throw createError;

        if (newProfile) {
          setRemainingGenerations(newProfile.remaining_generations || 0);
        }
      }
    } catch (error) {
      console.error('Error in fetchGenerations:', error);
      toast.error('Error loading your generations. Please try refreshing the page.');
    } finally {
      setLoading(false);
    }
  };

  const refreshGenerations = async () => {
    setLoading(true);
    await fetchGenerations();
  };

  const decrementGenerations = async () => {
    if (!user) return;
    
    try {
      const { data, error } = await supabase.rpc('decrement_generations', {
        user_id: user.id
      });

      if (error) throw error;

      await fetchGenerations();
      return data;
    } catch (error) {
      console.error('Error decrementing generations:', error);
      toast.error('Error updating generations. Please try again.');
      throw error;
    }
  };

  const addPremiumGenerations = async () => {
    try {
      if (!user) return;

      const { data, error } = await supabase
        .from('profiles')
        .update({ 
          remaining_generations: remainingGenerations + HOLIDAY_BONUS_GENERATIONS,
          is_premium: true,
          updated_at: new Date().toISOString()
        })
        .eq('id', user.id)
        .select()
        .single();

      if (error) throw error;
      
      if (data) {
        setRemainingGenerations(data.remaining_generations);
        toast.success('Holiday bundle generations added successfully!');
      }
    } catch (error) {
      console.error('Error adding holiday bundle generations:', error);
      toast.error('Failed to add generations. Please try again.');
    }
  };

  // Set up realtime subscription for profile changes
  useEffect(() => {
    if (!user) return;

    console.log('Setting up generations subscription for user:', user.id);

    const channel = supabase
      .channel('profile-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'profiles',
          filter: `id=eq.${user.id}`,
        },
        async (payload) => {
          console.log('Profile changed:', payload);
          await fetchGenerations();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [user]);

  // Initial fetch
  useEffect(() => {
    fetchGenerations();
  }, [user]);

  return (
    <GenerationsContext.Provider
      value={{
        remainingGenerations,
        loading,
        refreshGenerations,
        decrementGenerations,
        addPremiumGenerations,
      }}
    >
      {children}
    </GenerationsContext.Provider>
  );
}

export function useGenerations() {
  const context = useContext(GenerationsContext);
  if (context === undefined) {
    throw new Error('useGenerations must be used within a GenerationsProvider');
  }
  return context;
}
